import { SignUpForm } from 'authentication/components'
import { Container, LightBackground } from 'onboarding/exports'

import { AsideContent } from './components'

export const RecruiterLandingPage = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const redirectUrl = urlParams.get('redirectUrl') || '/recruiter-onboarding'

  return (
    <Container
      asideContent={<AsideContent />}
      background={<LightBackground />}
      asideClassName="max-w-[897px] h-[calc(100vh-40px)] m-0"
      containerClassNames="items-center"
      contentClassNames="max-w-[490px]"
      chooseDifferentAccount="Not a freelance recruiter?"
    >
      <SignUpForm redirectUrl={redirectUrl} theme="light" hideLogo />
    </Container>
  )
}
