import { Link } from 'react-router-dom'

import { Text } from 'shared/components/atoms'
import { IconBanner } from 'shared/components/molecules'

import { ReactComponent as AlertIcon } from 'icons/alert-triangle--filled.svg'

import { usePendingTasks } from '../../hooks'

import { JobOpportunity } from 'job-opportunity/domain'
import { VettingPackageResponse } from 'vetting/domain'

interface JobOpportunityOnHoldProps {
  appliedJobOpportunities: Array<JobOpportunity>
  vetting?: VettingPackageResponse
}

export const JobOpportunityOnHold = ({
  appliedJobOpportunities,
  vetting,
}: JobOpportunityOnHoldProps) => {
  const { holdJobOpportunityTasks } = usePendingTasks(vetting)

  if (!appliedJobOpportunities.length || !holdJobOpportunityTasks.length) return null

  return (
    <IconBanner
      icon={<AlertIcon className="w-6 h-6 fill-warning-dark" />}
      bgColor="bg-warning-lighter"
      border="border-warning-light"
    >
      <Text>
        Your English Check recording is still pending. Job opportunities will remain on hold until
        you <Link to="/english-vetting">record your English Check</Link>.
      </Text>
    </IconBanner>
  )
}
