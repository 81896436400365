import { Label, SelectTags, Suggestion } from 'shared/components/atoms'
import { Placeholder, Row } from 'shared/components/organisms'
import { FormControl, useStacksList, useStacks } from 'shared/hooks'

interface SelectStacksProps {
  formControl: FormControl
  name: string
  selectedOptions: Array<Suggestion>
  label?: string
  placeholder?: string
  error?: string
  allowCreation?: boolean
  shouldPublish?: boolean
  hideTags?: boolean
  warningOnly?: boolean
  max?: number
  containerClassName?: string
  children?: React.ReactNode
  labelSize?: 'text-base' | 'text-xs' | 'text-sm'
  labelWeight?: 'font-normal' | 'font-medium'
  onAdd?: (value: Suggestion) => void
}

export const SelectStacks = ({
  formControl,
  name,
  selectedOptions,
  label,
  placeholder,
  error,
  allowCreation,
  shouldPublish,
  hideTags,
  warningOnly,
  max,
  containerClassName,
  children,
  labelSize,
  labelWeight,
  onAdd,
}: SelectStacksProps) => {
  const { watch, setValue, getError } = formControl

  const { createStack } = useStacks()
  const { stacks, isLoading } = useStacksList()

  const handleCreateStack = (label: string) => createStack({ label, shouldPublish })

  if (isLoading) {
    return (
      <>
        {label && (
          <Label htmlFor={name} size={labelSize} weight={labelWeight}>
            {label}
          </Label>
        )}
        <Placeholder>
          <Row height="h-10" width="w-12/12" />
        </Placeholder>
      </>
    )
  }

  return (
    <>
      {label && (
        <Label htmlFor={name} size={labelSize} weight={labelWeight}>
          {label}
        </Label>
      )}
      <SelectTags
        name={name}
        options={stacks}
        selectedOptions={selectedOptions}
        setValue={setValue}
        watch={watch}
        error={error || getError(name)}
        warningOnly={warningOnly}
        allowCreation={allowCreation}
        onCreateOption={handleCreateStack}
        containerClassName={containerClassName}
        maxTagsNumber={max}
        maxLength={40}
        placeholder={placeholder}
        hideTags={hideTags}
        onAdd={onAdd}
      >
        {children}
      </SelectTags>
    </>
  )
}
