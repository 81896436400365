import { Roles } from 'account/enums'
import { Mode, SidePanelContainer } from './SidePanelContainer'
import { Testimonials } from './SidePanelContainer/Testimonials'
import { Text } from 'shared/components/atoms'
import { Developers } from './SidePanelContainer/Developers'
import { AsideContent } from 'authentication/pages/LandingPages/pages/RecruiterLandingPage/components'
import { TrustpilotScore } from 'authentication/pages/LandingPages/pages/RecruiterLandingPage/components/AsideContent/components'

interface SidePanelProps {
  accountRole?: Roles
}

const SidePanel = ({ accountRole }: SidePanelProps) => {
  const content: Record<
    Roles.JobSeeker | Roles.EmployerOwner,
    {
      mode: Mode
      title: string
      content: React.ReactNode
      titleClassName?: string
      containerClassName?: string
    }
  > = {
    [Roles.JobSeeker]: {
      title: 'Join Strider for high-paying, remote jobs with US companies',
      mode: 'darkMode',
      content: (
        <>
          <div className="relative w-full overflow-hidden h-[346px]">
            <div className="absolute flex h-[346px]">
              <Testimonials />
              <Testimonials />
            </div>
          </div>
          <TrustpilotScore theme={accountRole === Roles.JobSeeker ? 'dark' : 'light'} />
        </>
      ),
    },
    [Roles.EmployerOwner]: {
      containerClassName: 'gap-y-8 items-start px-12 py-15 bg-opacity-60 w-fit',
      title: 'Learn how Strider can help you',
      titleClassName: 'text-start',
      mode: 'lightMode',
      content: (
        <div className="flex flex-col gap-8">
          <ul className="list-disc pl-4">
            <li>
              <Text size="text-lg">Hire elite developers;</Text>
            </li>
            <li>
              <Text size="text-lg">Fill positions in 1-2 weeks;</Text>
            </li>
            <li>
              <Text size="text-lg">Save on average 42% versus a US hire.</Text>
            </li>
          </ul>
          <Text size="text-lg">
            <span className="font-medium">35,000+</span> Developers are waiting for you.
          </Text>
        </div>
      ),
    },
  }

  if (!accountRole || accountRole === Roles.EmployerViewer) {
    return (
      <SidePanelContainer
        mode="lightMode"
        title="Strider connects exceptional developers with top companies"
      >
        <div className="relative w-full overflow-hidden h-[346px]">
          <div className="absolute flex h-[346px]">
            <Developers />
            <Developers />
          </div>
        </div>
      </SidePanelContainer>
    )
  }

  if (accountRole === Roles.Recruiter) {
    return <AsideContent />
  }

  return (
    <SidePanelContainer
      mode={content[accountRole].mode}
      title={content[accountRole].title}
      hideCompanies={accountRole === Roles.EmployerOwner}
      titleClassName={content[accountRole].titleClassName}
      containerClassName={content[accountRole].containerClassName}
    >
      {content[accountRole].content}
    </SidePanelContainer>
  )
}

export { SidePanel }
