import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { plainToInstance } from 'class-transformer'

import { useNetwork } from 'shared/hooks'
import { ChangeLog } from 'candidates-search/domain'
import { TimelineFilterValues } from 'candidates-search/domain'
import { FetchTimelineDTO } from 'candidates-search/dtos'

const PAGE_SIZE = 30

interface Props {
  profileId: string
  filter: Optional<TimelineFilterValues>
  page: number
  enabled: boolean
}

export const useFetchTimeline = ({ profileId, page, enabled, filter }: Props) => {
  const { post } = useNetwork()

  const [hasMore, setHasMore] = useState(true)
  const [timelineItems, setTimelineItems] = useState<Array<ChangeLog>>([])
  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['timeline', profileId],
    () => {
      if (!filter) return

      const payload = new FetchTimelineDTO({
        profileId,
        filter,
        page,
        pageSize: PAGE_SIZE,
      }).toJSON()

      if (!payload.eventTypes?.length) return []

      return post<Array<ChangeLog>>(`/change-logs`, payload).then(({ data }) =>
        plainToInstance(ChangeLog, data),
      )
    },
    { enabled: Boolean(profileId) && enabled },
  )

  useEffect(() => {
    if (!data) return

    if (!page) setTimelineItems([])
    setTimelineItems((prev) => [...prev, ...data])
    setHasMore(data.length >= PAGE_SIZE)
  }, [data])

  return { timelineItems, isLoading: isLoading || isRefetching, hasMore, refetch }
}
