import { forwardRef, useCallback, useEffect, useState } from 'react'

import { ClaimProtected, InfiniteScroll, Loading, Text } from 'shared/components/atoms'
import { LazyInitializing, MobileWarning } from 'shared/components/molecules'
import { Placeholder, Row } from 'shared/components/organisms'
import { TimelineFilterValues } from 'candidates-search/domain'
import { AccountClaim, useDebounce } from 'shared/hooks'

import { TimelineFilter, TimelineItem } from './components'
import { useFetchTimeline } from './hooks'

interface TimelineProps {
  profileId: string
  scrollableTarget?: string
  matchingId?: string
  showMissingMatchingBanner?: boolean
}

const TimelineComponent = forwardRef(function Timeline(
  { profileId, scrollableTarget, matchingId, showMissingMatchingBanner }: TimelineProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const [filter, setFilter] = useState<TimelineFilterValues>()
  const { debouncedValue: debouncedFilter } = useDebounce(filter, 500)

  const [page, setPage] = useState<number>(0)
  const { timelineItems, refetch, hasMore, isLoading } = useFetchTimeline({
    profileId,
    filter: debouncedFilter,
    page,
    enabled: true,
  })

  const validItems = timelineItems.filter((item) => item.description)

  const handleFetchMore = useCallback(() => {
    setPage((prev) => prev + 1)
  }, [page])

  useEffect(() => {
    if (!profileId) return
    refetch()
  }, [page])

  useEffect(() => {
    if (!profileId) return

    if (!page) refetch()
    setPage(0)
  }, [debouncedFilter])

  return (
    <section
      ref={ref}
      className="print:hidden hidden sm:flex flex-col gap-6 pb-[72px] relative mb-10"
    >
      <Text size="text-xl" weight="font-medium">
        Timeline
      </Text>
      <TimelineFilter
        profileId={profileId}
        matchingId={matchingId}
        showMissingMatchingBanner={
          showMissingMatchingBanner && !matchingId && !filter?.selectedMatchingIds.length
        }
        onFilterChange={setFilter}
      />
      <div className="min-h-[600px]">
        {isLoading && !page ? (
          <div className="flex justify-center py-4">
            <Loading size="xl" bgColor="bg-neutral-day" />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={timelineItems.length}
            hasMore={hasMore}
            isFirstPage={page === 0}
            onFetchMore={handleFetchMore}
            scrollableTarget={scrollableTarget}
          >
            <ul className="p-[1px]">
              {validItems.map((item, index) => (
                <TimelineItem
                  key={`${item.id}-${index}`}
                  item={item}
                  isLast={index === validItems.length - 1}
                />
              ))}
            </ul>
          </InfiniteScroll>
        )}
      </div>
    </section>
  )
})

export const Timeline = forwardRef(function Timeline(
  props: TimelineProps,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <>
      <MobileWarning
        title="Timeline not available on mobile"
        text="Please use a larger-screen device to access this feature."
        className="sm:hidden mt-10"
      />
      <ClaimProtected require={AccountClaim.TimelineView}>
        <LazyInitializing
          fallback={
            <Placeholder className="gap-4">
              <Row width="w-4/12" height="h-10" />
              <Row width="w-12/12" height="h-10" />
              <Row width="w-12/12" height="h-40" />
            </Placeholder>
          }
        >
          <TimelineComponent {...props} ref={ref} />
        </LazyInitializing>
      </ClaimProtected>
    </>
  )
})
