import { useQuery } from 'react-query'
import { useNetwork } from '../network'

export const useStacksList = () => {
  const { get } = useNetwork()

  const { data: stacks = [], isLoading } = useQuery(
    'stacks',
    async () =>
      await get<Array<{ id: number; label: string }>>('listings-stacks').then(({ data }) => data),
    {
      staleTime: 3 * 60 * 1000,
    },
  )

  return { stacks, isLoading: isLoading }
}
